import BusinessModel from "@/components/common/BusinessModel";
import IndustryServeSlider from "@/components/common/IndustryServeSlider";
import PortfolioOverview from "@/components/common/PortfolioOverview";
import PrimaryButton from "@/components/common/PrimaryButton";
import AboutUs from "@/components/home/AboutUs";
import HomeHero from "@/components/home/HomeHero";
import HorizontalTab from "@/components/home/HorizontalTab";
import Modal from "@/components/home/Modal";
import ServicesCard from "@/components/home/ServicesCard";
import Layout from "@/components/layout/Layout";
import { cmsUrl } from "@/utils/helper";
import axios from "axios";
import { API_URL, LINK_OF_ALL_PAGES } from "const/common";
import { WE_EXCEL_DATA } from "const/Home-Page";
import dynamic from "next/dynamic";

const DesignShots = dynamic(() => import("@/components/home/DesignShots"));

const Home = ({ data }) => {
  const Title = "Software Development Services | ultroNeous Technologies";
  return (
    <Layout
      canonicalLink="https://ultroneous.com/"
      description="ultroNeous Technologies Is a Renowned Software Development Company In Usa & India. Our Expert Developers Help Your Business Drive Revenue & Growth."
      keywords="software development outsourcing services, web and app development company, custom software development company, Software Development Company"
      ogDescription="ultroNeous Technologies is a renowned software development company in USA & India. Our expert developers help your business drive revenue & growth."
      ogImage="https://ultroneous.com/assets/home/home-og.webp"
      ogTitle={Title}
      ogType="Web and Mobile App Development Company"
      ogUrl="https://ultroneous.com/"
      title={Title}
      twitterDescription="ultroNeous Technologies is a renowned software development company in USA & India. Our expert developers help your business drive revenue & growth."
      twitterImage="https://ultroneous.com/assets/home/home-og.webp"
      twitterTitle={Title}
    >
      <HomeHero />
      <ServicesCard />
      <div className="bg-primary-black pb-16 xl:px-16 md:pb-32">
        <HorizontalTab data={WE_EXCEL_DATA} title="Technologies we work with" />
        <div className="text-center">
          <PrimaryButton
            customStyle
            link={LINK_OF_ALL_PAGES.mainPages.expertise}
            val="View All Technologies"
          />
        </div>
      </div>
      <PortfolioOverview />
      <IndustryServeSlider />
      <BusinessModel />
      <AboutUs />
      <DesignShots data={data} />
      <Modal />
    </Layout>
  );
};

export const getStaticProps = async () => {
  let designData;
  let blogData;
  try {
    designData = await axios
      .get(`${API_URL}admin/getlookatourdesign`)
      .then((getlookatourdesign) => getlookatourdesign);
  } catch (error) {
    designData = { data: [] };
  }

  try {
    blogData = await axios
      .get(`${cmsUrl}/wp-json/wp/v2/posts?categories=1`)
      .then((getblog) => getblog);
  } catch (error) {
    blogData = { data: [] };
  }

  return {
    props: {
      data: {
        designShots: designData ? designData?.data : { data: [] },
        blog: blogData ? blogData?.data : { data: [] },
      },
    },
  };
};

export default Home;
