import { IS_MOBILE } from "const/common";
import Image from "next/image";
import Link from "next/link";
import { useCallback, useEffect, useState } from "react";

const Modal = () => {
  const [modalOpen, setModalOpen] = useState(true);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    setIsMobile(IS_MOBILE(600));
    document.body.style.overflow = modalOpen ? "hidden" : "unset";
    const timeoutId = setTimeout(() => {
      if (modalOpen) {
        setModalOpen(false);
      }
    }, 15000);
    return () => {
      document.body.style.overflow = "unset";
      clearTimeout(timeoutId);
    };
  }, [modalOpen]);

  const closeModal = useCallback(() => {
    setModalOpen(false);
  }, []);

  return (
    modalOpen && (
      <div className="fixed inset-0 bg-black/60 z-50">
        <div className="flex justify-center items-center h-full px-4 md:px-0">
          <div className="relative">
            <div>
              <Image
                src={
                  isMobile ? "/assets/expo-mobile.webp" : "/assets/expo.webp"
                }
                width={isMobile ? 350 : 600}
                height={isMobile ? 546 : 700}
                alt="expo"
                loading="eager"
                quality={100}
              />
            </div>
            <div className="absolute top-[53%] md:top-[335px] left-1/2 transform -translate-x-1/2 -translate-y-1/2">
              <Link
                href="https://calendly.com/sachinpadhiyar/business-alliance?month=2024-10"
                passHref
              >
                <a
                  className="py-1.5 px-4 md:py-2 md:px-6 text-[11px] md:text-sm font-extrabold bg-gradient-expo text-white rounded md:rounded-lg"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Book Now
                </a>
              </Link>
            </div>
            <div
              onClick={closeModal}
              className="absolute top-3 right-3 md:top-4 md:right-5 cursor-pointer"
            >
              <Image
                src="/assets/close-button.svg"
                width={25}
                height={25}
                alt="close"
              />
            </div>
          </div>
        </div>
      </div>
    )
  );
};

export default Modal;
